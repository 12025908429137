import type React from 'react';
import { Fieldset, Field, Label, Description } from '@headlessui/react';
import clsx from 'clsx';

interface LabelProps {
  className?: string;
  htmlFor?: string;
  children: React.ReactNode;
}

export const PtnLabel: React.FC<LabelProps> = ({
  children,
  htmlFor = '',
  className = '',
  ...props
}) => {
  return (
    <Label
      htmlFor={htmlFor}
      className={clsx('text-16 font-bold text-gray-5', className)}
      {...props}
    >
      {children}
    </Label>
  );
};

interface FieldProps {
  className?: string;
  children: React.ReactNode;
}

export const PtnField: React.FC<FieldProps> = ({
  children,
  className = '',
  ...props
}) => {
  return (
    <Field className={className} {...props}>
      {children}
    </Field>
  );
};

interface FieldsetProps {
  className?: string;
  children: React.ReactNode;
}

export const PtnFieldset: React.FC<FieldsetProps> = ({
  children,
  className = '',
  ...props
}) => {
  return (
    <Fieldset className={clsx('space-y-1.5', className)} {...props}>
      {children}
    </Fieldset>
  );
};

interface DescriptionProps {
  className?: string;
  children: React.ReactNode;
}

export const PtnDescription: React.FC<DescriptionProps> = ({
  children,
  className = '',
  ...props
}) => {
  return (
    <Description className={clsx('text-12 text-gray-40', className)} {...props}>
      {children}
    </Description>
  );
};
